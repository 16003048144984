<template>
  <div class="flex items-center space-x-3" data-cy="block-phone-actions">
    <div
      v-tooltip="t('ACTIVITY.CALLS.BAD_NUMBER')"
      class="action-wrapper"
      :class="[phoneClasses?.badNumber?.wrapper, { 'cursor-pointer': isAdmin }]"
      @click="setActiveAction(actions.badNumber)"
    >
      <icon-base
        :icon="IconBadNumber"
        width="12"
        height="12"
        data-cy="bad-number-icon"
        :class="phoneClasses?.badNumber?.icon"
      />
    </div>
    <div
      v-tooltip="t('ACTIVITY.CALLS.DO_NOT_CONTACT')"
      class="action-wrapper"
      :class="[phoneClasses?.dnc?.wrapper, { 'cursor-pointer': isAdmin }]"
      @click="setActiveAction(actions.dnc)"
    >
      <icon-base
        :icon="IconClear"
        data-cy="do-not-contact-icon"
        class="transform scale-50"
        :class="phoneClasses?.dnc?.icon"
      />
    </div>
  </div>
  <lf-modal
    v-if="showModal"
    :title="modalTitle"
    small
    title-border
    modal-class="z-[101]"
    :close="() => (showModal = false)"
  >
    <template #content>
      <div
        :data-cy="isUnsettingStatus ? 'unmark-phone-modal' : 'mark-phone-modal'"
        class="p-6 space-x-1"
      >
        <span class="text-headline">
          {{
            $t("ACTIVITY.CALLS.MODAL_BLOCK_BODY_START", {
              action: isUnsettingStatus
                ? $t("ACTIVITY.CALLS.UNMARK").toLowerCase()
                : $t("ACTIVITY.CALLS.MARK").toLowerCase()
            })
          }}
        </span>
        <span
          class="text-xxs p-1 rounded-full font-semibold"
          :class="activeAction.class"
        >
          {{ `${activeAction.text.toUpperCase()}?` }}
        </span>
        <span v-if="!isUnsettingStatus" class="text-headline">
          {{ activeAction.text }}
          {{ $t("ACTIVITY.CALLS.MODAL_BLOCK_BODY_END") }}
        </span>
        <hr class="my-4" />
        <div class="flex justify-end">
          <modal-buttons
            class="bg-white py-5"
            :disabled="loading"
            :on-cancel="() => (showModal = false)"
            :on-save="blockTelephone"
            :save-button-text="$t('COMMON.CONFIRM')"
          />
        </div>
      </div>
    </template>
  </lf-modal>
</template>
<script setup lang="ts">
import IconBadNumber from "@/components/icons/IconBadNumber.vue";
import IconClear from "@/components/icons/IconClear.vue";
import IconBase from "@/components/ui/IconBase.vue";
import LfModal from "@/components/ui/Modal.vue";
import ModalButtons from "@/components/ui/ModalButtons.vue";

import { TelephoneStatus } from "@/enums/communicationLogs";
import type { DealTelephone } from "@/models/users";

import { useAuth } from "@/hooks/auth";
import { usePromiseWrapper } from "@/hooks/common";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { useStore } from "vuex";
import { useDealsBase } from "@/hooks/deals";

interface Props {
  telephoneInfo: DealTelephone | null;
  contactName: string;
  dealId?: string;
  isBusiness?: boolean;
}

const props = defineProps<Props>();

const { t } = useI18n();

const actions = {
  dnc: {
    text: t("ACTIVITY.CALLS.DO_NOT_CONTACT"),
    class: "text-error bg-error-light",
    status: TelephoneStatus.DoNotContact
  },
  badNumber: {
    text: t("ACTIVITY.CALLS.BAD_NUMBER"),
    class: "text-warning bg-warning-light",
    status: TelephoneStatus.BadNumber
  }
} as const;

const { isAdmin } = useAuth();
const { dispatch } = useStore();
const { activeDeal } = useDealsBase();

const showModal = ref(false);
const activeAction = ref<(typeof actions)[keyof typeof actions]>(actions.dnc);

const isUnsettingStatus = computed(
  () => props.telephoneInfo?.status === activeAction.value.status
);

const modalTitle = computed(() => {
  const start = isUnsettingStatus.value
    ? t("ACTIVITY.CALLS.UNMARK_NUMBER_AS")
    : t("ACTIVITY.CALLS.MARK_NUMBER_AS");
  return `${start} ${activeAction.value.text}?`;
});

const phoneClasses = computed(() => {
  const phone = props.telephoneInfo;
  const classes = {
    badNumber: {
      wrapper: "flex bg-disabled",
      icon: "text-label"
    },
    dnc: {
      wrapper: "flex bg-disabled",
      icon: "text-label"
    }
  };

  if (!phone || (phone.status === TelephoneStatus.Active && !isAdmin)) {
    classes.badNumber.wrapper = "hidden";
    classes.dnc.wrapper = "hidden";
    return classes;
  }

  if (phone.status === TelephoneStatus.Active) {
    return classes;
  }

  if (phone.status === TelephoneStatus.BadNumber) {
    classes.badNumber.wrapper = "flex bg-warning-light";
    classes.badNumber.icon = "text-warning";

    if (!isAdmin) {
      classes.dnc.wrapper = "hidden";
    }

    return classes;
  }

  classes.dnc.wrapper = "flex bg-error-light";
  classes.dnc.icon = "text-error";

  if (!isAdmin) {
    classes.badNumber.wrapper = "hidden";
  }

  return classes;
});

const setActiveAction = (action: (typeof actions)[keyof typeof actions]) => {
  if (!isAdmin || !props.telephoneInfo) {
    return;
  }
  activeAction.value = action;
  showModal.value = true;
};

const { fetchWrapper: blockTelephone, loading } = usePromiseWrapper(
  async () => {
    const applicationId = activeDeal.value.id ?? props.dealId;
    if (!props.telephoneInfo || !applicationId) {
      return;
    }
    const payload = {
      applicationId,
      phone: {
        number: props.telephoneInfo.number,
        status: isUnsettingStatus.value
          ? TelephoneStatus.Active
          : activeAction.value.status,
        name: props.contactName
      },
      shouldBlock: !isUnsettingStatus.value,
      isBusiness: !!props.isBusiness
    };
    await dispatch("applications/updateApplicationOwnerPhoneStatus", payload);
    showModal.value = false;
  }
);
</script>
<style scoped>
.action-wrapper {
  @apply w-4 h-4 rounded-full items-center justify-center;
}

.modal-wrapper {
  z-index: 101;
}
</style>
